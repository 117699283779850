.poppinsFont{
  font-family: 'Poppins', sans-serif !important;
}
.robotoFont{
  font-family: 'Roboto', sans-serif;
}
.background{
  background-image: url('../assets/images/background_signup.png');
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  position: absolute;
  border-radius: 0px 0px 35px 35px;
  height: 70%;
  width: 100%;
}
/* SLIDER START*/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fafafb;
  border: 1px solid rgb(218, 218, 218);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 3px;
  background-color: #ffc34d;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #fafafb;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* SLIDER END*/
.containerSignup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 100px; */
  margin-top: 5rem;
  font-family: 'Poppins';
}
.clientSelectContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-radius: 14px;
  position: absolute;
  /* margin-top: 70px; */
  /* margin-top: 70%; */
  top: 70%;
  width: 600px;
  border: 1px solid #fafafb;
}
.clientSelectSignUp{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 12px;
  /* width:480px; */
}
.buttonSignUpGrey{
  width: 100%;
  height: 45px;
  margin-top: 25px;
  border: none;
  background-color: #f1f1f5;
  border-radius: 10px;
  color: gray;
  font-size: large;
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 14px;
}
.buttonSignUpBlue{
  width: 100%;
  height: 45px;
  margin-top: 25px;
  border: none;
  background-color: #66b0ff;
  border-radius: 10px;
  color: white;
  font-size: large;
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 14px;
}
.steps{
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.stepNumber{
  font-size: 14px;
  margin-left: 6px;
}
.stepDone{
  border-radius: 10px 0px 0px 10px;
  background-color: #66b0ff;
  height: 5px;
  width: 45px;
}
.stepUndone{
  background-color: grey;
  height: 5px;
  width: 45px;
}
.bigText{ 
  color: white;
  font-size: 50px;
  text-align: center;
  width: 80%;
}