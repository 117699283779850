.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.d-flex {
  display: flex !important;
  height: 80vh;
}

.center {
  margin: 0 auto;
  align-self: center;
}

/* make the customizations */
$theme-colors: (
  // "info": tomato,
  // "danger": teal,
  "breadcrumb" : #e9ecef
);

$spacer: 1rem !default;
$spacers: (
  ) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((0: 0,
      1: ($spacer * .25), //4px
      2: ($spacer * .5), //8px
      3: $spacer, //16px
      4: ($spacer * 1.5), //24px
      5: ($spacer * 3), //48px
      6: ($spacer * 4), //64px
      7: ($spacer * 5), //80px
      8: ($spacer * 6.25), //100px
      9: ($spacer * 7.5), //120px
      10: ($spacer * 9.375), //150px
      15: ($spacer * 18) //300px
    ),
    $spacers
);

.form-control:disabled,
.form-control[readonly] {
  background-color: rgba(249, 249, 249, 255) !important;
}

@import "~bootstrap/scss/bootstrap";

.tlaComponent {
  height: 600px;
  float: left;
  margin-right: 6px;
  border-width: 1px;
  border-color: map-get($theme-colors, "primary");
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.listDetailComponents {
  display: row;
  flex-direction: row;
}

.bodyList {
  flex-direction: column;
}

.thList {
  flex-direction: row;
}

.button {
  background-color: transparent;
  border-color: transparent;
  border-width: 0px;
}

.breadcrumb {
  background-color: transparent;
  padding: 0px !important;
  margin-bottom: 0px !important;

  .breadcrumb>li+li:before {
    color: #ccc;
    content: "";
    padding: 0 5px;
  }
}

.clientsPriceList {
  cursor: pointer;

  :hover {
    background-color: #ebf0fa
  }
}

.pinIcons {
  color: white;
  width: 22px;
  height: 22px;
  border-radius: 7px;
}

.pin {
  background-color: #0062cc;
  border-width: medium;
  border-color: white;
  border-style: solid;
  border-radius: 50%;
  // opacity: 0.3;
}

// .pin:hover{
//   opacity: 1;
// }
.plantAvatar {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.mapButtons {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  top: 0px;
  right: 0px;
}

.switchMapButtonLeft {
  width: 38px;
  height: 38px;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.switchMapButtonRight {
  margin-left: 1px;
  width: 38px;
  height: 38px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.switchContainer {
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: #fafafb;
  // min-width: 1200px;
  // overflow: hidden !important;
  // height: calc(100vh - 83px);
}

.hrVer {
  border: none;
  border-left: 1px solid hsla(200, 10%, 50%, 100);
  border-color: #e6e6e6;
  height: 7vh;
  width: 1px;
  margin: 0px 15px 15px 15px;
}

.loggedAvatar {
  width: 50px;
  height: 50px;
  border-radius: 30px;
  margin-right: 10px;
}

.poppinsFont {
  font-family: 'Poppins', sans-serif;
}

.robotoFont {
  font-family: 'Roboto', sans-serif !important;
}

.logo {
  background-image: url("./assets/logo.png");
  background-position: center;
  background-size: cover;
  width: 205px;
  height: 34px;
}

.right {
  -webkit-transform: rotate(270deg);
}

.detailText {
  font-size: 14px !important;
}

.autosuggest {
  border: 1px solid #e6e6e6;
  color: "black";
  border-radius: 10px;
  width: 230px;
  height: 40px;
}

.autosuggestFilter {
  border: 1px solid #bfbfbf;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 385px;
  height: 40px;
}

.autosuggestFilterExplore {
  border: 1px solid #bfbfbf;
  border-radius: 14px;
  background-color: #f2f2f2;
  width: 250px;
  height: 40px;
}

.autosuggestNewBillboard {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  padding-left: 13px;
  border: 1px solid #e6e6e6;
  color: "black";
  border-radius: 10px;
  width: 249px;
  height: 40px;
}

//// NASCONDERE FRECCE NUMERI IN INPUT
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

//////////////////////////////////////
.borderSelected {
  border: 1px solid #00e68a;
  border-radius: 14px;
}

.backgroundSelected {
  color: white;
  background-color: #00e68a !important;
}

.priceIcon {
  margin-right: 5px;
  padding: 10px;
  background-color: rgb(241, 241, 241);
  border-radius: 30px;
}

.super-cluster {
  color: #fff;
  background: #0062FF;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -20px;
  right: -20px;
  font-size: 14px;
  border: 2px solid #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 98, 255, 0.25);
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cluster-marker-false {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cluster-marker-true {
  color: #fff;
  background: #00e68a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconOverview {
  background-color: #e6f2ff;
  border-color: transparent;
  border-radius: 10px;
}

.billaloIcon {
  background-image: url('./assets/Billalo_logo.png');
  background-size: cover;
  width: 40px;
  height: 40px;
}

.hoverPointer {
  :hover {
    cursor: pointer !important;
  }
}

.overviewDetailRow {
  background-color: #66b0ff;
  color: white;
}

.DayPicker-Month {
  width: 480px !important;
}

.DayPicker-Day--highlighted {
  background-color: #00bcd4;
  border-radius: 10px !important;
  color: white;
}

.buttonAvailability {
  position: absolute;
  z-index: 4;
  right: 65px;
  top: 65px;
}

.buttonMonthAvailability {
  position: absolute;
  z-index: 4;
  left: 65px;
  top: 65px;
}

.availabilityText {
  font-size: 11px;
}

.disponibility {
  font-family: 'Roboto', sans-serif !important;
}

.notDisponibility {
  font-family: 'Roboto', sans-serif !important;
  color: red;
}

.selected {
  color: #0062cc;
  border-bottom-width: 4px;
  border-bottom-color: #0062cc;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
}

.ordersAvatar {
  width: 50px;
  height: 50px;
  border-radius: 15px;
}

.hoverPointerOrders {
  :hover {
    cursor: pointer;
    border: 1px solid #69deaf;
    border-radius: 14px;
  }
}

.hoverPointerOrdersRow {
  :hover {
    cursor: pointer;
    border: 0px solid #69deaf;
    border-radius: 0px;
  }
}

.orderNumberMap {
  width: 300px;
  height: 100px;
  border-radius: 14px;
  background-color: #fafafb;
}

button:focus {
  outline: none;
}

.orderDetailsLeft {
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
  width: 380px !important;
  height: 78vh;
  overflow-x: auto;
}

*:focus {
  outline: 0 !important;
}

///PERSONALIZZARE SIDEBAR
/* width */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  margin-right: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
}

/////////////////////////
.borderColorDatePicker {
  border-width: 1 !important;
  border-style: "solid" !important;
  border-color: "#e6e6e6" !important;
  border-radius: 10px !important;
  align-items: "flex-end" !important;
  justify-content: "center" !important;
  width: 227px !important;
  height: 39px !important;
}

///PERSONALIZZARE SIDEBAR
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey;
  border-radius: 10px;
  margin: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
}

/////////////////////////
.selectedPage {
  color: #0062cc;
  padding-bottom: 10px;
  border-bottom: 3px solid #0062cc;
}

.upperIndex {
  z-index: 4 !important;
}

.backgroundIconColor {
  background-color: #fafafb;
  // background-color: red;
  border-radius: 7px;
  // padding: 6px 0px 6px 0px;
  height: 35px;
  // width: 20px;
}

.noOverflowX {
  overflow-x: hidden !important;
}

.noOverflowY {
  overflow-y: hidden !important;
}

// input[type=button] {
//   :focus{
//     outline: 0 !important;
//   }
// }
// *:focus {
//   outline: 0 !important;
// }
// button:focus {outline:0;}
.MuiBadge-badge {
  /// camba il colore del badge di material_Ui
  background-color: #3dd598 !important;
}

.modal-content {
  /// bordi dei modali
  border-radius: 15px !important;
}

/// VISUALIZZAZIONE STATI
.maintenanceOverview {
  align-items: center;
  background-color: #e8fff6;
  color: #3dd598;
  padding: 2px 4px 2px 4px;
  border-radius: 7px;
}

.confirmedOverview {
  align-items: center;
  background-color: rgba(61, 213, 152, 0.1);
  color: #3dd598;
  padding: 2px 4px 2px 4px;
  border-radius: 7px;
}

.exitOverview {
  align-items: center;
  background-color: rgba(80, 181, 255, 0.1);
  color: #50B5FF;
  padding: 2px 4px 2px 4px;
  border-radius: 7px;
}

.pendingOverview {
  align-items: center;
  background-color: rgba(255, 197, 66, 0.1);
  color: #ffc542;
  padding: 2px 4px 2px 4px;
  border-radius: 7px;
}

.endedOverview {
  align-items: center;
  background-color: #e2e2ea;
  color: #8b8b96;
  padding: 2px 4px 2px 4px;
  border-radius: 7px;
}

.negativeOverview {
  align-items: center;
  background-color: rgba(252, 90, 90, 0.1);
  color: #fc5a5a;
  padding: 2px 4px 2px 4px;
  border-radius: 7px;
}

///////////////////
.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    display: flex;
    align-items: "center";
    background-color: white;
    border-width: 1px;
    border-color: #f1f1f5;
    border-style: solid;
    border-radius: 5px;
    padding: 15px;
    font-size: 15px;
  }
}

.notificationTransition {
  height: 100px !important;
}

.selectedPlanningType {
  color: #0062cc;
  border-left-color: #0062cc;
  border-left-width: 7px;
  border-left-style: solid;
  border-radius: 0px 10px 10px 0px;
}

// .MuiPaper-root.MuiPopover-paper.MuiPaper-rounded {
//   top: unset !important;
//   bottom: 0 !important;
// }